@import './theme/fonts';

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: var(--elevated-2);
  border-left: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--component-border);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--component-border);
}

/* Corner */
::-webkit-scrollbar-corner {
  background: var(--component-border);
}

//================================================================

html,
body {
  font-size: 14px;
  background-color: #121212 !important;
  font-family: $text-fonts;
  height: 100%;
}

a {
  text-decoration: none;
}

//================================================================

@media only screen and (max-width: 960px) {
  .Toastify__toast {
    height: 50px;
    top: -10px !important;
    padding-left: 24px;
    position: fixed;
    left: 0;
    right: 0;
  }
}

.Toastify__toast {
  padding: 0 16px;
  min-height: 38px;
  border-radius: 3px;
  font-size: 16px;
  font-family: $text-fonts;
  font-weight: bold;
  color: var(--color-text-main);
  background-color: var(--elevated-6);
  box-shadow: var(--elevated-shadow-3);

  .Toastify__close-button {
    display: none !important;
  }

  &--error {
    background-color: var(--deribit-ask);
  }

  &--success {
    background-color: var(--deribit-bid);
  }

  &--info {
    background-color: var(--deribit-green);
  }
}

.Toastify__progress-bar {
  height: 2px;

  &--info {
    background-color: var(--deribit-green);
  }

  &--error {
    background-color: var(--deribit-red);
  }

  &--success {
    background-color: var(--deribit-green);
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}

.notiEnter {
  animation-name: zoomIn;
}

.notiExit {
  animation-name: zoomOut;
}

.NavFooter {
  height: 48px;
  display: flex;
  box-shadow: var(--elevated-shadow-1);
  overflow: auto;
  overflow-y: hidden;
}

@media only screen and (max-width: 960px) {
  .NavFooter {
    position: fixed;
    top: 48px;
    left: 0;
    right: 0;
  }
}
