@import '../../theme/fonts';

.DualLineChart {
  overflow: hidden;
  touch-action: none;

  @media only screen and (max-width: 960px) {
    position: fixed;
    top: 96px;
    bottom: 0px;
  }
}

.Logo {
  bottom: 36px;
  left: 10px;
  position: absolute;
  opacity: 0.6;
}

.Progress {
position: absolute;
left: 0;
top: 0;
right: 0;
bottom: 0;
margin: auto;
}

.ProgressColorPrimary {
  color: var(--mute-3) !important;
}
