@import '../../theme/fonts';

.Nav {
  display: flex;
  height: 48px;
  background-color: var(--elevated-2);
  overflow: hidden;

  .NavLogo {
    cursor: pointer;
    padding: 0 10px 0 20px;
    display: flex;
    align-items: center;
    height: 48px;
    box-sizing: border-box;
    vertical-align: middle;

    a {
      height: 100%;
      display: flex;
    }
  }

  .NavMenuButton {
    height: 48px;
    padding-right: 20px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    border-right: var(--border-width) solid var(--component-border);
  }
}

.NavItem {
  height: 48px;
  box-sizing: border-box;
  vertical-align: middle;
  display: inline-block;
  color: var(--color-text-main);
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-left: var(--border-width) solid var(--component-border);
  border-right: var(--border-width) solid var(--component-border);
  margin-left: -1px !important;
  position: relative;

  &:first-child {
    margin-left: 0 !important;
  }

  &-inner {
    display: inline-flex;
    align-items: center;
    height: 100%;
    font-size: 13px;
    width: 100%;
  }

  &-active {
    background-color: var(--elevated-5);
  }

  a,
  a:hover {
    color: var(--color-text-main);
  }

  span,
  button {
    color: var(--mute-1);
    cursor: pointer;
    padding: 0 24px;

    svg {
      color: var(--color-text-main);
      position: relative;
      top: 0.5px;
    }
  }

  button {
    background: none;
    border: none;
  }
}

.LastGridItem {
  flex-grow: 1 !important;
}

@media only screen and (max-width: 960px) {
  .Nav {
    position: fixed;
    z-index: 999;

    .NavMenuButton {
      padding: 0 20px;
      border-right: none;
    }

    .NavItem {
      border-right: none;
      border-left: none;
    }
  }
}
