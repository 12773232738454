@import '../../../theme/fonts';

.Actions {
  display: flex;
  position: absolute;
  height: 40px;
  margin-top: 8px !important;
  padding-left: 8px;
  align-items: center;

  .Slider {
    width: 300px;
    margin-right: 8px;
    padding: 2px 54px 0 54px;
    border-radius: 4px;
  }

  .Switches {
    padding-left: 18px;
  }

  .Slider,
  .Switches {
    display: flex;
    height: 100%;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.05);
  }
}
