@import '../../../theme/fonts';

.Tooltip {
  background-color: var(--elevated-6) !important;
  font-size: 11px !important;
  font-family: $text-fonts !important;
  border-radius: 3px !important;
  color: var(--color-text-main) !important;
  box-shadow: var(--elevated-shadow-1) !important;
  opacity: 1 !important;
}
