@import '../../theme/fonts';

.PositionBuilder {
  padding: 12px 24px 24px 24px;
  height: 100%;
}

.Buy {
  color: var(--color-text-main);
  background-color: var(--deribit-bid);

  &:hover {
    background-color: var(--deribit-bid-hover);
  }

  &:focus {
    background-color: var(--deribit-bid-selected);
  }
}

.Sell {
  color: var(--color-text-main);
  background-color: var(--deribit-ask);

  &:hover {
    background-color: var(--deribit-ask-hover);
  }

  &:focus {
    background-color: var(--deribit-ask-selected);
  }
}

small {
  color: var(--deribit-red);
  text-align: center;
  display: block;
}

.Label {
  font-size: 10px;
  color: var(--mute-1);
  margin-bottom: 6px;
  z-index: 1;
  display: block;
  width: fit-content;
  padding: 0;
  line-height: 10px;
}

.TabRoot {
  min-width: unset !important;
  color: var(--mute-1) !important;
  font-family: $text-fonts !important;
  font-weight: bold !important;
  font-size: 11px !important;
  min-height: 30px !important;
  overflow: visible !important;
  opacity: 1 !important;
}

.TabsRoot {
  border-bottom: 1px solid var(--elevated-0);
  margin: 0 auto 16px;
  min-height: 30px !important;
  overflow: visible !important;
}

.TabsFixed {
  overflow: visible !important;
}

.TabsIndicator {
  bottom: -2px !important;
  background-color: var(--color-text-main) !important;
  height: 1px !important;
}

.Divider {
  background-color: var(--component-border) !important;
  margin-bottom: 6px !important;
  height: 1px !important;
}

.TotalWrapper {
  font-size: 11px;
  display: inline-block;
  width: 100%;
  color: var(--mute-1);

  @media only screen and (max-width: 960px) {
    font-size: 12px;
  }
}

.TotalWrapperLeft {
  float: left;
  display: block;
  font-family: $text-fonts !important;
}

.TotalWrapperRight {
  float: right;
  display: block;
  font-family: $number-fonts !important;
}

.InfoLabel {
  font-size: 9px;
  color: var(--mute-2);

  @media only screen and (max-width: 960px) {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 960px) {
  .PositionBuilder {
    margin-bottom: 48px;
  }
}
