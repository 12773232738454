@import './theme/fonts';

.flexlayout__layout {
  left: 8px;
  top: 54px;
  right: 8px;
  bottom: 8px;
  position: absolute;

  color: #ddd;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background: transparent;
}

.flexlayout__splitter {
  background-color: transparent;
}

.flexlayout__splitter:hover {
  background-color: transparent;
}

.flexlayout__splitter_drag {
  border-radius: 5px;
  background-color: #fff;
  z-index: 1000;
}

.flexlayout__outline_rect {
  position: absolute;
  cursor: move;
  border: 2px solid #cfe8ff;
  box-shadow: inset 0 0 60px rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  z-index: 1000;
  box-sizing: border-box;
}

.flexlayout__outline_rect_edge {
  cursor: move;
  border: 2px solid #b7d1b5;
  box-shadow: inset 0 0 60px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1000;
  box-sizing: border-box;
}

.flexlayout__edge_rect {
  position: absolute;
  z-index: 1000;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  /* background-color: lightgray; */
}

.flexlayout__drag_rect {
  position: absolute;
  cursor: move;
  border: 2px solid #aaaaaa;
  box-shadow: inset 0 0 60px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  z-index: 1000;
  box-sizing: border-box;
  background-color: #eeeeee;
  opacity: 0.9;
  text-align: center;

  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  padding: 10px;
  word-wrap: break-word;
}

.flexlayout__tabset {
  overflow: hidden;
  box-sizing: border-box;
}

.flexlayout__tab {
  overflow: auto;
  position: absolute;
  box-sizing: border-box;
  color: white;
  background-color: #1b2431;
}

.flexlayout__tab_button {
  cursor: pointer;
  padding: 10px 24px 10px;
  float: left;
  vertical-align: top;
  box-sizing: border-box;
  /* text-transform: uppercase; */
  font-size: 12px;
  font-weight: bold;
}

.flexlayout__tab_button--selected {
  color: #fff;
}

.flexlayout__tab_button--unselected {
  color: gray;
}

.flexlayout__tab_button_leading {
  float: left;
  display: inline-block;
  margin-right: 8px;
}

.flexlayout__tab_button_content {
  float: left;
  display: inline-block;
}

.flexlayout__tab_button_textbox {
  float: left;
  border: none;
  color: lightgreen;
  background-color: #273142;
}

.flexlayout__tab_button_textbox:focus {
  outline: none;
}

.flexlayout__tab_button_trailing {
  float: left;
  display: inline-block;
  margin-left: 5px;
  margin-top: 3px;
  width: 8px;
  height: 8px;

  background: transparent
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAB3RJTUUH3wsFFistRLa7pwAAAB1pVFh0Q29tbWVudAAAAAAAQ3JlYXRlZCB3aXRoIEdJTVBkLmUHAAAAKklEQVQY02P4////fwYcAC6HTRGGGLIATlP/QwGyGBMDsQCvFXgdScibAEpfN8vaz7EaAAAAAElFTkSuQmCC)
    no-repeat 50%;
}

.flexlayout__tab_button:hover .flexlayout__tab_button_trailing,
.flexlayout__tab_button--selected .flexlayout__tab_button_trailing {
  /* background: transparent url("../images/close_white.png") no-repeat center; */
}

.flexlayout__tab_button_overflow {
  float: left;
  width: 20px;
  height: 15px;
  margin-top: 2px;
  padding-left: 12px;
  border: none;
  font-size: 10px;
  color: lightgray;
  font-family: Arial, sans-serif;
  background: transparent
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAYAAACALL/6AAAABmJLR0QAgQCBAIEPjeBcAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAB3RJTUUH3wsOCSUBKJaZhgAAAB1pVFh0Q29tbWVudAAAAAAAQ3JlYXRlZCB3aXRoIEdJTVBkLmUHAAABsUlEQVQY003IvavTUBiA8ffNyRka0g9p4Vwamk0IlE6FLu3geB1Eusgd2wTCJXChcC3V/6CLSKXWCroKrjo6uWg/ECpR6FDv0kFIhizlVHJyelxE7rP9HuL7Pti2rTPGSKfT0cMwlN1uV2eMkXa7rYdhKIMggPV6DQAA6HkeEUI8yLLsHiGEmKY5ORwOd6WU54QQrVgsPp9OpzdBEMBsNgMEABiNRg7n/Aul9A7nPDQM44kQ4i2l9Ixz/gMAHs7n85vBYAC4Wq2w1Wop13XPbNv+jYgQRdEnIcRjy7K+n04niOP4M6X00WQyiXCz2aCUEprNpgIAGI/HSggBSZJ8ME3zwjAM/s8fc7nchU4IwWq1CgCgfN8fFQoFyLIMLMt6t1wurxqNBkgpgTH23vd9jkopREQ1HA79Uqn0ulwug67rL5Ik+ZWm6aRSqQCl9E2/379CxD9E0zRar9cv8/n8K8YYKKWe7Xa7n1mWvWSMoa7rU9d1rxHxuN/vQYvjWDLGvjmO0z8ej+e9Xu9pFEVfHce5TNP0vud514jIt9st1Go1AAAApRQopRBudduLxeL//wu1cMHCeTkryAAAAABJRU5ErkJggg==)
    no-repeat 0;
}

.flexlayout__tabset_header {
  position: absolute;
  left: 0;
  right: 0;
  color: #eee;
  /* background-color: #212121; */
  padding: 3px 3px 3px 5px;
  /*box-shadow: inset 0px 0px 3px 0px rgba(136, 136, 136, 0.54);*/
  box-sizing: border-box;
}

.flexlayout__tab_header_inner {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 10000px;
  background-color: var(--elevated-3);
}

.flexlayout__tabset-selected {
  background-image: linear-gradient(#2d3744, #1b2431);
}

.flexlayout__tabset-maximized {
  background: #1b2431 linear-gradient(#2d3744, #1b2431);
}

.flexlayout__tab_toolbar {
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
  padding: 6px;
}

.flexlayout__popup_menu {
  background: #273142;
}

.flexlayout__popup_menu_item {
  padding: 2px 10px 2px 10px;
  color: #ddd;
  font-size: 13px;
  cursor: pointer;
}

.flexlayout__popup_menu_item:hover {
  background-color: #ffffff1a;
}

.flexlayout__popup_menu_container {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #555;
  /* background: #222; */
  border-radius: 3px;
  position: absolute;
  z-index: 1000;
}

.flexlayout__border_top {
  /* background-color:black; */
  /* border-bottom: 1px solid #ddd; */
  box-sizing: border-box;
  overflow: hidden;
}

.flexlayout__border_bottom {
  /* background-color:black; */
  border-top: 1px solid #333;
  box-sizing: border-box;
  overflow: hidden;
}

flexlayout__border_left {
  /* background-color:black; */
  border-right: 1px solid #333;
  box-sizing: border-box;
  overflow: hidden;
}

.flexlayout__border_right {
  /* background-color:black; */
  border-left: 1px solid #333;
  box-sizing: border-box;
  overflow: hidden;
}

.flexlayout__border_inner_bottom {
  display: flex;
}

.flexlayout__border_inner_left {
  position: absolute;
  white-space: nowrap;
  right: 23px;
  transform-origin: top right;
  transform: rotate(-90deg);
}

.flexlayout__border_inner_right {
  position: absolute;
  white-space: nowrap;
  left: 23px;
  transform-origin: top left;
  transform: rotate(90deg);
}

.flexlayout__border_button {
  /* background-color: #222; */
  color: white;
  display: inline-block;
  white-space: nowrap;

  cursor: pointer;
  padding: 2px 8px 3px 8px;
  margin: 2px;
  vertical-align: top;
  box-sizing: border-box;
}

.flexlayout__border_button--selected {
  color: #ddd;
  /* background-color: #222; */
}

.flexlayout__border_button--unselected {
  color: gray;
}

.flexlayout__border_button_leading {
  float: left;
  display: inline;
}

.flexlayout__border_button_content {
  display: inline-block;
}

.flexlayout__border_button_textbox {
  float: left;
  border: none;
  color: green;
  /* background-color:#ddd; */
}

.flexlayout__border_button_textbox:focus {
  outline: none;
}

.flexlayout__border_button_trailing {
  display: inline-block;
  margin-left: 5px;
  margin-top: 3px;
  width: 8px;
  height: 8px;
}

.flexlayout__border_button:hover .flexlayout__border_button_trailing,
.flexlayout__border_button--selected .flexlayout__border_button_trailing {
  /* background: transparent url("../images/close_white.png") no-repeat center; */
}

.flexlayout__border_toolbar_left {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
}

.flexlayout__border_toolbar_right {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
}

.flexlayout__border_toolbar_top {
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
}

.flexlayout__border_toolbar_bottom {
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
}

.flexlayout__tab {
  background-color: var(--elevated-3);
  z-index: 1;
}

.flexlayout__tabset {
  background-color: var(--elevated-3);
  box-shadow: var(--elevated-shadow-2);
  z-index: 1;
}

.flexlayout__tab_header_inner {
  min-width: 300px;
}

.flexlayout__tab_button {
  padding: 0 8px 0 0;
  margin: 0 8px;
  display: flex;
  align-items: center;
  color: var(--mute-2);
  font-family: $text-fonts;
  box-sizing: border-box;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  border-bottom: 2px solid transparent;
}

.flexlayout__tab_button--selected {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  z-index: 1;
  position: relative;
  color: var(--color-text-main);
}

.flexlayout__tab_button--selected:not(:only-child) {
  border-bottom-color: var(--color-text-main);
}

.flexlayout__tab_header_outer {
  overflow: visible;
  white-space: nowrap;
  width: calc(100% + 1px);
  cursor: pointer;
  position: relative;
  border: none;
}

.flexlayout__splitter_drag {
  background-color: var(--color-text-main);
}

.flexlayout__tabset-selected {
  background-image: none;
}

.flexlayout__tab_toolbar_button-min,
.flexlayout__tab_toolbar_button-max {
  display: none;
}
