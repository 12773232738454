@import './mixins';
@import './fonts';

%component-main {
  @include user-select(none);
  box-sizing: border-box;
  background-color: var(--component-bg);
  border: var(--border-width) solid var(--component-border);
  border-radius: 2px;
  font-size: 12px;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: #fff;
}

%hide-scrollbar-style {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

%title {
  font-weight: bold;
  font-family: $text-fonts;
  text-transform: uppercase;
  letter-spacing: 0.07272727em;
}

%number-style {
  font-family: $number-fonts;
  font-size: 13px;
  padding-right: 4px;
}

%table-header-style {
  font-family: inherit;
  height: 24px;
  font-weight: 400;
  font-size: 11px;
  color: var(--mute-1);
  box-sizing: border-box;
  background-color: var(--header-bg);
  border-bottom: 2px solid var(--component-border);
}

%switchers-style {
  font-size: 11px !important;
  color: var(--mute-1);
}

%scrollbar-style {
  scrollbar-color: #3a4959 #222c3c;
  scrollbar-width: thin;
}
