@import './theme/fonts';

svg {
  .tick text {
    fill: var(--mute-1);
  }

  .tick line {
    stroke: var(--elevated-6);
    stroke-width: var(--border-width);
    stroke-opacity: 0.5;
    shape-rendering: geometricPrecision;
  }

  .domain {
    stroke: transparent;
    stroke-width: 0;
  }

  path {
    shape-rendering: geometricPrecision;
  }

  text {
    fill: var(--color-text-main);
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

button:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
}
