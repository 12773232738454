@import '../../../theme/fonts';

.NavItem {
  min-width: 130px;

  span {
    margin-right: 8px;
    padding: 0 !important;
  }

  border-right: none !important;

  &PriceText {
    display: inline;
  }
}

.Button {
  width: 100%;
  border: none;
  font-size: 13px;
  font-weight: bold;
  height: 50px;
  color: var(--color-text-main);
  position: relative;
  outline: none;
  cursor: pointer;
  background-color: var(--elevated-2);
  text-align: left;
  padding-left: 15px;
  font-family: $text-fonts;
  border-bottom: var(--border-width) solid var(--component-border);

  &LastGridItem {
    flex-grow: 1;
    text-align: right;
    margin-right: 24px !important;
    font-weight: normal;
  }

  .Icon {
    padding-right: 8px;
  }
}

.CurrencyMenu {
  background-color: var(--elevated-3) !important;
  max-width: 960px !important;
  width: 100% !important;
  border-radius: 0 !important;
  left: 0 !important;
  right: initial !important;
  bottom: 0 !important;
  top: 50px !important;
  position: fixed;

  span {
    margin-left: 9px;
  }
}

.Divider {
  background-color: var(--component-border) !important;
  margin: 10px 15px !important;
}

.Indices {
  overflow: hidden;
  display: inline-block;
  font-size: 13px;
  font-weight: bold;

  span {
    font-family: $text-fonts;
    font-weight: bold;
    letter-spacing: 0.0727272em;
    color: var(--color-text-main);
  }

  button {
    font-size: 14px;
  }
}
