@import '../../theme/fonts';
@import '../../theme/styles';

.Logo {
  height: 76px;
  padding: 0 24px;
  border-bottom: 1px solid var(--mobile-elevated-6) !important;
}

.Container {
  @extend %hide-scrollbar-style;
  width: 292px !important;
  overflow: auto;
  padding-bottom: env(safe-area-inset-bottom);

  .Title {
    @extend %title;
    padding-left: 24px;
    font-size: 10px;
    color: var(--mute-1);
    height: 46px;
    display: flex;
    align-items: center;
  }

  .MenuItem {
    cursor: pointer;
    font-family: $text-fonts;
    padding: 0 24px;
    height: 46px;
    font-size: 12px;
    color: var(--mute-1) !important;
    display: flex;
    align-content: center;
    align-items: center;
    font-weight: bold;

    .Leading {
      width: 30px;
    }

    &:hover {
      background-color: var(--elevated-6);
    }
  }

  .Anchor {
    width: 100%;
  }
}

.Divider {
  border: none;
  margin: 4px 0 4px 54px !important;
  background-color: var(--mobile-elevated-6) !important;
}
