.CustomHeader {
  .MenuHeader {
    opacity: 0;
    transition: opacity 0.2s ease 0s;
  }

  .SortHeader {
    height: 32px;
    display: flex;
    align-items: center;

    .SortIcon {
      width: 24px;
      display: flex;
      justify-content: center;
    }

    span {
      line-height: initial;
      border-bottom: 1px dotted var(--component-border);
    }

    .HeaderText {
      text-overflow: ellipsis !important;
      overflow: hidden !important;
      border-bottom: 1px dotted var(--mute-1);
      cursor: help;
    }
  }
}

.CustomHeader:hover > .MenuHeader {
  opacity: 1;
}
