@import '../../theme/fonts';

#FuturePositions,
#OptionPositions {
  .ag-root {
    background-color: var(--elevated-3);
    border: none;
    color: var(--color-text-main);
  }

  .ag-row-level-0 {
    border-top: calc(1px / var(--pixel-divider)) solid var(--component-border) !important;
  }

  .ag-row-group-indent-0,
  .ag-row-group-indent-1 {
    color: var(--mute-2);
  }

  .ag-status-bar {
    display: none;
  }

  .ag-numeric-cell {
    font-family: $number-fonts;
    font-size: 12px;
  }

  .ag-body-viewport {
    top: -1px;
  }

  .ag-header {
    background-color: transparent;
    border-top: 1px solid;
    border-color: var(--component-border);
    color: var(--mute-2);
    font-weight: normal;
    font-size: 11px;
    z-index: 999;

    &-cell-moving {
      background-color: var(--elevated-6);
      color: var(--color-text-main);
    }

    &-cell {
      display: flex !important;
      align-items: center !important;
    }

    &-cell::after {
      margin-top: 0;
      height: 100%;
      border-right: var(--border-width) solid var(--elevated-6);
    }
  }

  .ag-header-cell-label {
    display: flex;
    align-items: center;
  }

  .ag-header-icon {
    display: flex;
    align-items: center;
  }

  .ag-popup-editor {
    border: none;
    border-radius: 2px;
  }

  .ag-rich-select {
    background-color: var(--elevated-3);
    border: var(--border-width) solid var(--component-border);
    color: var(--color-text-main-darken);
    cursor: pointer;

    &-value {
      border-bottom: var(--border-width) solid var(--component-border);
    }

    &-row-selected {
      background-color: var(--elevated-3);
      color: var(--color-text-main);
      cursor: pointer;
    }

    &-list {
      height: 56px;
    }
  }

  .ag-menu {
    background-color: var(--elevated-3);
    border: var(--border-width) solid var(--component-border);
    color: var(--color-text-main-darken);
    cursor: pointer;

    &-option-active {
      cursor: pointer;
      background-color: var(--elevated-3);
    }

    &:hover {
      color: var(--color-text-main);
    }
  }

  .ag-row {
    background-color: var(--elevated-3);
    border: none;
    color: var(--mute-3);
    cursor: pointer;
    font-size: 12px;
    font-family: $text-fonts;

    &-selected {
      color: var(--color-text-main-darken);
    }

    &-group {
      color: var(--color-text-main);
    }

    &-selected.long {
      color: var(--deribit-green);
    }

    &-selected.short {
      color: var(--deribit-red);
    }

    &:hover {
      background-color: var(--elevated-6);
    }
  }

  .ag-cell-edit-input {
    background-color: var(--elevated-2);
    border: none;
    color: inherit;
    text-align: right;
  }

  .ag-cell-inline-editing {
    cursor: pointer;
    background: var(--elevated-2);
    padding-right: 5px;
    padding-top: 1px;
    border: var(--border-width) solid var(--component-border);
    margin-top: -1px;
  }

  .ag-cell:focus {
    border: var(--border-width) solid var(--component-border);

    .red {
      background-color: red;
    }
  }

  .ag-cell-data-changed {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .ag-icon {
  }
}

.customHeaderMenuButton {
  float: left;
  margin: 0 0 0 3px;
}

.customHeaderLabel {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0 -4px 0 3px;
}

.customSortDownLabel {
  float: left;
  margin: 0 0 0 3px;
}

.customSortUpLabel {
  float: left;
  margin: 0;
}

.customSortRemoveLabel {
  float: left;
  margin: 0 0 0 3px;
  font-size: 11px;
}

.active {
  color: cornflowerblue;
}
