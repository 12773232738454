.Progress {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.ProgressColorPrimary {
  color: #15c8b1 !important;
}
